import { REHYDRATE } from 'redux-persist/lib/constants';
import { REMOVE_USER, SAVE_USER } from './actionTypes';

const initialState = {};

const User = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_USER:
            state = { ...state, ...action.payload };
            break;
        case REMOVE_USER:
            state = {};
            break;
        case REHYDRATE:
            if (action.payload) {
                state = { ...state, ...action.payload.User };
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default User;
