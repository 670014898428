import { REHYDRATE } from 'redux-persist/lib/constants';
import { REMOVE_CENTER, SAVE_CENTER } from './actionTypes';

const initialState = {};

const Center = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_CENTER:
            state = { ...state, ...action.payload };
            break;
        case REMOVE_CENTER:
            state = {};
            break;
        case REHYDRATE:
            if (action.payload) {
                state = { ...state, ...action.payload.Center };
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default Center;
