import { SAVE_USER, REMOVE_USER } from './actionTypes';

export const saveUser = (user) => {
    return {
        type: SAVE_USER,
        payload: user,
    };
};

export const removeUser = () => {
    return {
        type: REMOVE_USER,
    };
};
