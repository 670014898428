import React, { useEffect, useRef, useState } from 'react';
import {
    Col,
    Container,
    Row,
    Form,
    FormFeedback,
    Label,
    Input,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Button,
    Card,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Spinner,
} from 'reactstrap';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { useSelector } from 'react-redux';
import {
    justNums,
    handleFormError,
    showToast,
    Thumbnail,
} from '../../helpers/common_helper';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';
import FeatherIcon from 'feather-icons-react';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { Link } from 'react-router-dom';

const Invoices = () => {
    document.title = 'Invoices | Kinderiffic';
    const offCanvasWidth = '35%';
    const CSVLinkEl = useRef(null);

    // States-E-Module
    const { user, center } = useSelector((state) => ({
        user: state.User,
        center: state.Center,
    }));
    const [offCanvasOpen, setOffCanvasOpen] = useState(false);
    const typeOptions = [
        { value: 'Services', label: 'Services' },
        { value: 'Inventory', label: 'Inventory' },
        { value: 'Resources', label: 'Resources' },
        { value: 'Expense', label: 'Expense' },
    ];

    // States-E-Form
    const [form_processing, set_form_processing] = useState(false);
    const [services_id, set_services_id] = useState(0);
    const [services_name, set_services_name] = useState('');
    const [services_desc, set_services_desc] = useState('');
    const [services_fee, set_services_fee] = useState('');
    const [services_cost, set_services_cost] = useState('');
    const [services_vat, set_services_vat] = useState('');
    const [tax_exempt, set_tax_exempt] = useState(0);
    const [services_type, set_services_type] = useState(null);
    const [services_minutes, set_services_minutes] = useState(60);
    const [services_status, set_services_status] = useState(1);

    // States-E-Datatable
    const [data, setData] = useState([]);
    const [CSVData, setCSVData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [sort, setSort] = useState({
        field: 'inv_id',
        dir: 'ASC',
    });
    const [errors, setErrors] = useState(null);

    // States-E-ColumnsVisibility
    const [hideInvNumCol, setHideInvNumCol] = useState(false);
    const [hideNameCol, setHideNameCol] = useState(false);
    const [hideCodeCol, setHideCodeCol] = useState(false);
    const [hideAmountCol, setHideAmountCol] = useState(false);
    const [hideVatCol, setHideVatCol] = useState(false);
    const [hideDateCol, setHideDateCol] = useState(false);
    const [hidePictureCol, setHidePictureCol] = useState(false);

    // Toggle off canvas
    const toggleOffCanvas = () => {
        setOffCanvasOpen(!offCanvasOpen);
    };

    // Handle selects
    const handleType = (type) => {
        set_services_type(type);
    };
    const selectFldStyle = {
        control: () => ({
            borderColor: handleFormError('services_type', errors).show
                ? '#f06548'
                : '#CFD4DA',
            borderWidth: 1,
            borderStyle: 'solid',
            display: 'flex',
            borderRadius: 5,
        }),
    };

    // Handle submit
    const handleSubmit = async (e) => {
        e.preventDefault();

        set_form_processing(true);

        const dataForm = document.getElementById('data-form');
        const fData = new FormData(dataForm);
        fData.append('center_id', user.center_id);
        fData.append('services_id', services_id);
        fData.append('Services[center_id]', user.center_id);
        fData.set(
            'Services[services_cost]',
            services_cost === '' ? 0 : services_cost
        );
        fData.set(
            'Services[services_vat]',
            services_vat === '' ? 0 : services_vat
        );
        fData.set('Services[tax_exempt]', tax_exempt);
        fData.set('Services[services_status]', services_status);
        try {
            const res = await axios.post(
                process.env.REACT_APP_API_URL +
                    '/services/' +
                    (services_id ? 'update' : 'insert'),
                fData,
                {
                    params: {
                        auth_key: user.auth_key,
                    },
                }
            );

            if (res.type) {
                showToast(res.message, 'success');
                resetForm();
                fetchData(currentPage);
            }

            if (!res.type) {
                showToast(res.message, 'danger');
                setErrors(res.data);
            }
        } catch (error) {
            showToast(error, 'danger');
        }
    };

    // Handle minutes
    const handleMinutes = (mins, action) => {
        if (action === null) {
            set_services_minutes(parseInt(mins));
        } else {
            if (action) {
                set_services_minutes(parseInt(mins + 1));
            } else {
                set_services_minutes(parseInt(mins - 1));
            }
        }
    };

    // Reset form
    const resetForm = () => {
        set_services_id(0);
        set_services_name('');
        set_services_desc('');
        set_services_fee('');
        set_services_cost('');
        set_services_vat('');
        set_tax_exempt(0);
        set_services_status(1);
        set_services_type(null);
        setErrors(null);
        set_form_processing(false);
        toggleOffCanvas();
    };

    // Edit
    const editThis = (row) => {
        console.log(row);

        toggleOffCanvas();

        const formFields = [
            { services_id: set_services_id },
            { services_name: set_services_name },
            { services_fee: set_services_fee },
            { services_cost: set_services_cost },
            { services_type: set_services_type },
            { services_minutes: set_services_minutes },
            { services_vat: set_services_vat },
            { tax_exempt: set_tax_exempt },
            { services_desc: set_services_desc },
            { services_status: set_services_status },
        ];

        formFields.map((item, index) => {
            const key = Object.keys(item)[0];
            if ('services_type' === key) {
                item[key]({
                    value: row[key],
                    label: row[key],
                });
            } else if ('tax_exempt' === key || 'services_status' === key) {
                item[key](parseInt(row[key]));
            } else {
                item[key](row[key]);
            }
        });
    };

    // Delete
    const deleteThis = async (id) => {
        try {
            const fData = new FormData();
            fData.append('services_id', id);
            fData.append('center_id', user.center_id);

            const res = await axios.post(
                process.env.REACT_APP_API_URL + '/services/delete',
                fData,
                {
                    params: {
                        auth_key: user.auth_key,
                    },
                }
            );

            if (res.type) {
                showToast(res.message, 'success');
                fetchData(currentPage);
            }

            if (!res.type) {
                showToast(res.message, 'danger');
                setErrors(res.data);
            }
        } catch (error) {
            showToast(error, 'danger');
        }
    };

    // Get children
    const getOptions = (input) => {
        console.log(input);
        // axios
        //     .get(
        //         `${process.env.REACT_APP_API_URL}/invoice/get-children?auth_key=${user.auth_key}&center_id=${user.center_id}&center_timezone=${center.center_timezone}`
        //     )
        //     .then((res) => {
        //         console.log(res.data.items);
        //     })
        //     .catch((err) => console.log(err));
    };

    // Datatable: columns
    const columns = [
        {
            key: null,
            name: 'Actions',
            selector: (row) => (
                <React.Fragment>
                    <Link
                        to={{
                            pathname: `/invoice-details`,
                            state: {
                                inv_id: row.inv_id,
                            },
                        }}
                        title='View'
                        color='secondary'
                        className='btn btn-secondary btn-sm'>
                        <i className='ri-eye-line' />
                    </Link>
                </React.Fragment>
            ),
            sortable: false,
            width: '100px',
        },
        {
            key: 'inv_num',
            name: 'Invoice #',
            selector: (row) => row.inv_num,
            sortable: true,
            omit: hideInvNumCol,
            hideColFunc: () => setHideInvNumCol(!hideInvNumCol),
        },
        {
            key: 'picture',
            name: 'Picture',
            width: '100px',
            selector: (row) => (
                <Thumbnail moduleName='children' fileName={row.picture} />
            ),
            sortable: true,
            omit: hidePictureCol,
            hideColFunc: () => setHidePictureCol(!hidePictureCol),
        },
        {
            key: 'child_name',
            name: 'Name',
            width: '150px',
            selector: (row) => row.child_name,
            sortable: true,
            omit: hideNameCol,
            hideColFunc: () => setHideNameCol(!hideNameCol),
        },
        {
            key: 'child_code',
            name: 'Code',
            width: '150px',
            selector: (row) => row.child_code,
            sortable: true,
            omit: hideCodeCol,
            hideColFunc: () => setHideCodeCol(!hideCodeCol),
        },
        {
            key: 'inv_amount',
            name: 'Amount',
            selector: (row) => row.inv_amount,
            sortable: true,
            omit: hideAmountCol,
            hideColFunc: () => setHideAmountCol(!hideAmountCol),
        },
        {
            key: 'inv_vat',
            name: 'VAT',
            selector: (row) => row.inv_vat,
            sortable: true,
            omit: hideVatCol,
            hideColFunc: () => setHideVatCol(!hideVatCol),
        },
        {
            key: 'inv_date',
            name: 'Date',
            selector: (row) => moment(row.inv_date).format('DD-MM-YYYY'),
            sortable: true,
            omit: hideDateCol,
            hideColFunc: () => setHideDateCol(!hideDateCol),
        },
    ];

    // Datatable: fetch data
    const fetchData = async (page) => {
        setLoading(true);

        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/invoice/fetch?auth_key=${
                user.auth_key
            }&center_id=${user.center_id}&offset=${
                (page - 1) * perPage
            }&order=${sort.field}&dir=${
                sort.dir
            }&page=${page}&limit=${perPage}&search=${searchKeyword}`
        );

        setData(response.data);
        setCurrentPage(page);
        setTotalRows(response.total_count_filtered);
        setLoading(false);
    };

    // Datatable: sort
    const handleSort = (column, sortDirection) => {
        const field = column.key;
        const dir = sortDirection.toUpperCase();

        setSort({ field, dir });
    };

    // Datatable: page change
    const handlePageChange = (page) => {
        fetchData(page);
    };

    // Datatable: per page rows change
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);

        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/services/fetch?auth_key=${
                user.auth_key
            }&center_id=${user.center_id}&offset=${
                (page - 1) * perPage
            }&order=${sort.field}&dir=${
                sort.dir
            }&page=${page}&limit=${newPerPage}&search=${searchKeyword}`
        );

        setData(response.data);
        setPerPage(newPerPage);
        setLoading(false);
    };

    // Datatable: header component
    const subHeaderComponent = (
        <React.Fragment>
            <Button
                color='primary'
                className='me-3'
                onClick={() => CSVExport()}>
                CSV
            </Button>
            <UncontrolledDropdown className='me-3'>
                <DropdownToggle
                    tag='button'
                    className='btn btn-light'
                    id='dropdownMenuButton'>
                    Columns Visibility <i className='mdi mdi-chevron-down'></i>
                </DropdownToggle>
                <DropdownMenu>
                    {columns
                        .filter((col) => col.key !== null)
                        .map((item, index) => (
                            <DropdownItem
                                key={index}
                                onClick={() => item.hideColFunc()}
                                className={item.omit ? 'text-muted' : ''}>
                                <FeatherIcon
                                    className='me-1'
                                    icon={item.omit ? 'eye-off' : 'eye'}
                                    size={14}
                                />{' '}
                                {item.name}
                            </DropdownItem>
                        ))}
                </DropdownMenu>
            </UncontrolledDropdown>
            <div className='form-icon left'>
                <Input
                    value={searchKeyword}
                    type='text'
                    className='form-control form-control-icon'
                    id='iconleftInput'
                    placeholder='Search here...'
                    onChange={(e) => setSearchKeyword(e.target.value)}
                />
                <i
                    className='mdi mdi-magnify search-widget-icon'
                    style={{ fontSize: '15px' }}></i>
            </div>
            <Button
                color='danger'
                className='btn-icon ms-2'
                title='Clear'
                onClick={() => setSearchKeyword('')}>
                <FeatherIcon icon='x' size={15} />
            </Button>
        </React.Fragment>
    );

    // Datatable: user effect
    useEffect(() => {
        fetchData(currentPage);
    }, [sort, searchKeyword]);

    // Datatable: export csv header
    const CSVHeader = () => {
        const filteredCol = columns
            .filter((column) => column.key !== null && !column.omit)
            .map((column) => {
                return { label: column.name, key: column.key };
            });

        return filteredCol;
    };

    // Datatable: export CSV
    const CSVExport = () => {
        const CSVHeaderKeys = CSVHeader().map((header) => header.key);

        const csvExportData = data.map((row) => {
            return Object.assign(
                {},
                ...Object.entries({ ...CSVHeaderKeys }).map(
                    ([item, index]) => ({
                        [index]: row[index],
                    })
                )
            );
        });

        setCSVData(csvExportData);

        setTimeout(() => {
            CSVLinkEl.current.link.click();
        });
    };

    // Datatable: toggle status
    const handleToggleStatus = async (id) => {
        const fData = new FormData();
        fData.append('center_id', user.center_id);
        fData.append('services_id', id);

        try {
            const res = await axios.post(
                process.env.REACT_APP_API_URL + '/services/toggle-status',
                fData,
                {
                    params: {
                        auth_key: user.auth_key,
                    },
                }
            );

            if (res.type) {
                showToast(res.message, 'success');
                fetchData(currentPage);
            }

            if (!res.type) {
                showToast(res.message, 'danger');
                setErrors(res.data);
            }
        } catch (error) {
            showToast(error, 'danger');
        }
    };

    return (
        <React.Fragment>
            <ToastContainer />
            <CSVLink
                headers={CSVHeader()}
                filename='services_export.csv'
                data={CSVData}
                ref={CSVLinkEl}
            />
            <div className='page-content'>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <div
                                className='page-title-box d-sm-flex align-items-center justify-content-between'
                                style={{ minHeight: '58px' }}>
                                <h4 className='mb-sm-0'>Invoices</h4>
                            </div>
                        </Col>
                    </Row>
                    <Card>
                        <CardBody>
                            <Row className='g-3'>
                                <Col md={6}>
                                    <div className='form-icon left'>
                                        <Input
                                            value={searchKeyword}
                                            type='text'
                                            className='form-control form-control-icon'
                                            id='iconleftInput'
                                            placeholder='Search with parent or child name'
                                            onChange={(e) =>
                                                setSearchKeyword(e.target.value)
                                            }
                                        />
                                        <i
                                            className='mdi mdi-magnify search-widget-icon'
                                            style={{ fontSize: '15px' }}></i>
                                    </div>
                                </Col>
                                <Col md={6} className='text-end'>
                                    <Button
                                        disabled={true}
                                        color='success'
                                        className='btn-label'>
                                        <i className='ri-file-text-line label-icon align-middle fs-16 me-2'></i>
                                        SAVE INVOICE
                                    </Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <DataTable
                                columns={columns}
                                data={data}
                                progressPending={loading}
                                pagination
                                paginationServer
                                paginationTotalRows={totalRows}
                                onChangePage={handlePageChange}
                                onChangeRowsPerPage={handlePerRowsChange}
                                subHeader
                                subHeaderComponent={subHeaderComponent}
                                onSort={handleSort}
                                sortServer
                            />
                        </CardBody>
                    </Card>
                </Container>
                <Offcanvas
                    style={{ width: offCanvasWidth }}
                    isOpen={offCanvasOpen}
                    toggle={resetForm}
                    direction='end'
                    className='offcanvas-end border-0'>
                    <OffcanvasHeader
                        className='d-flex align-items-center p-3 border-bottom'
                        toggle={resetForm}>
                        <span className='m-0 me-2'>
                            {services_id ? 'Update' : 'Add'} Service
                        </span>
                    </OffcanvasHeader>
                    <OffcanvasBody>
                        <Form
                            id='data-form'
                            onSubmit={handleSubmit}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                            }}>
                            <div style={{ flex: 1 }}>
                                <Row>
                                    <Col md={6}>
                                        <div className='mb-3'>
                                            <Label
                                                htmlFor='services_name'
                                                className='form-label'>
                                                Name
                                            </Label>
                                            <Input
                                                name='Services[services_name]'
                                                value={services_name}
                                                onChange={(e) =>
                                                    set_services_name(
                                                        e.target.value
                                                    )
                                                }
                                                type='text'
                                                className='form-control'
                                                placeholder=''
                                                id='services_name'
                                                invalid={
                                                    handleFormError(
                                                        'services_name',
                                                        errors
                                                    ).show
                                                }
                                            />
                                            <FormFeedback>
                                                {
                                                    handleFormError(
                                                        'services_name',
                                                        errors
                                                    ).msg
                                                }
                                            </FormFeedback>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className='mb-3'>
                                            <Label
                                                htmlFor='services_fee'
                                                className='form-label'>
                                                Fee
                                            </Label>
                                            <Input
                                                name='Services[services_fee]'
                                                value={services_fee}
                                                onChange={(e) =>
                                                    justNums(e.target.value) &&
                                                    set_services_fee(
                                                        e.target.value
                                                    )
                                                }
                                                type='text'
                                                className='form-control'
                                                placeholder=''
                                                id='services_fee'
                                                invalid={
                                                    handleFormError(
                                                        'services_fee',
                                                        errors
                                                    ).show
                                                }
                                            />
                                            <FormFeedback>
                                                {
                                                    handleFormError(
                                                        'services_fee',
                                                        errors
                                                    ).msg
                                                }
                                            </FormFeedback>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className='mb-3'>
                                            <Label
                                                htmlFor='services_cost'
                                                className='form-label'>
                                                Cost
                                            </Label>
                                            <Input
                                                name='Services[services_cost]'
                                                value={services_cost}
                                                onChange={(e) =>
                                                    justNums(
                                                        e.target.value,
                                                        true
                                                    ) &&
                                                    set_services_cost(
                                                        e.target.value
                                                    )
                                                }
                                                type='text'
                                                className='form-control'
                                                placeholder='0.00'
                                                id='services_cost'
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className='mb-3'>
                                            <Label
                                                htmlFor='services_vat'
                                                className='form-label'>
                                                VAT
                                            </Label>
                                            <Input
                                                name='Services[services_vat]'
                                                value={services_vat}
                                                onChange={(e) =>
                                                    justNums(
                                                        e.target.value,
                                                        true
                                                    ) &&
                                                    set_services_vat(
                                                        e.target.value
                                                    )
                                                }
                                                type='text'
                                                className='form-control'
                                                placeholder='0.00'
                                                id='services_vat'
                                            />
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className='mb-3'>
                                            <Label className='services_type'>
                                                Type
                                            </Label>
                                            <Select
                                                className={
                                                    handleFormError(
                                                        'services_type',
                                                        errors
                                                    ).show && 'is-invalid'
                                                }
                                                styles={selectFldStyle}
                                                name='Services[services_type]'
                                                value={services_type}
                                                onChange={() => {
                                                    handleType();
                                                }}
                                                options={typeOptions}
                                                invalid={true}
                                                id='services_type'
                                            />
                                            <FormFeedback>
                                                {
                                                    handleFormError(
                                                        'services_type',
                                                        errors
                                                    ).msg
                                                }
                                            </FormFeedback>
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className='mb-3'>
                                            <Label
                                                htmlFor='services_desc'
                                                className='form-label'>
                                                Description
                                            </Label>
                                            <textarea
                                                value={services_desc}
                                                onChange={(e) =>
                                                    set_services_desc(
                                                        e.target.value
                                                    )
                                                }
                                                name='Services[services_desc]'
                                                className='form-control'
                                                id='services_desc'
                                                rows='3'></textarea>
                                        </div>
                                    </Col>
                                    <Col sm={6}>
                                        <div>
                                            <Label className='form-label'>
                                                Minutes
                                            </Label>
                                            <br />
                                            <div className='input-step'>
                                                <button
                                                    type='button'
                                                    className='minus'
                                                    onClick={() =>
                                                        handleMinutes(
                                                            services_minutes,
                                                            false
                                                        )
                                                    }>
                                                    –
                                                </button>
                                                <Input
                                                    name='Services[services_minutes]'
                                                    type='number'
                                                    className='product-quantity'
                                                    value={services_minutes}
                                                    style={{ width: '100px' }}
                                                    min='0'
                                                    onChange={(e) =>
                                                        justNums(
                                                            e.target.value
                                                        ) &&
                                                        handleMinutes(
                                                            e.target.value,
                                                            null
                                                        )
                                                    }
                                                />
                                                <button
                                                    type='button'
                                                    className='plus'
                                                    onClick={() =>
                                                        handleMinutes(
                                                            services_minutes,
                                                            true
                                                        )
                                                    }>
                                                    +
                                                </button>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={3}>
                                        <div className='mb-3'>
                                            <Label
                                                htmlFor='taxEx'
                                                className='form-label'>
                                                Tax Exempt
                                            </Label>
                                            <div
                                                className='form-check form-switch form-switch-lg pt-1'
                                                dir='ltr'>
                                                <Input
                                                    name='Services[tax_exempt]'
                                                    onChange={() =>
                                                        set_tax_exempt(
                                                            tax_exempt === 0
                                                                ? 1
                                                                : 0
                                                        )
                                                    }
                                                    type='checkbox'
                                                    className='form-check-input'
                                                    id='customSwitchsizelg'
                                                    defaultChecked={tax_exempt}
                                                    value={tax_exempt}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={3}>
                                        <div className='mb-3'>
                                            <Label
                                                htmlFor='status'
                                                className='form-label'>
                                                Status
                                            </Label>
                                            <div
                                                className='form-check form-switch form-switch-lg pt-1'
                                                dir='ltr'>
                                                <Input
                                                    name='Services[services_status]'
                                                    onChange={() =>
                                                        set_services_status(
                                                            services_status ===
                                                                0
                                                                ? 1
                                                                : 0
                                                        )
                                                    }
                                                    type='checkbox'
                                                    className='form-check-input'
                                                    id='customSwitchsizelg'
                                                    defaultChecked={
                                                        services_status
                                                    }
                                                    value={services_status}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                <Row>
                                    <Col md={12}>
                                        <div className='text-end mb-4'>
                                            <Button
                                                disabled={form_processing}
                                                color={
                                                    services_id
                                                        ? 'warning'
                                                        : 'success'
                                                }
                                                className='btn-load'>
                                                <span className='d-flex align-items-center'>
                                                    {form_processing && (
                                                        <Spinner
                                                            size='sm'
                                                            className='flex-shrink-0 me-2'></Spinner>
                                                    )}
                                                    <span className='flex-grow-1'>
                                                        {services_id
                                                            ? 'Update'
                                                            : 'Add'}{' '}
                                                        Invoice
                                                    </span>
                                                </span>
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Form>
                    </OffcanvasBody>
                </Offcanvas>
            </div>
        </React.Fragment>
    );
};

export default Invoices;
