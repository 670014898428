import React from 'react';
import { withRouter } from 'react-router-dom';
import Particles from 'react-tsparticles';

const ParticlesAuth = ({ children }) => {
    return (
        <React.Fragment>
            <div className='auth-page-wrapper pt-5'>
                <div
                    className='auth-one-bg-position auth-one-bg'
                    id='auth-particles'>
                    <div className='bg-overlay'></div>

                    <div className='shape'>
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            version='1.1'
                            xmlnsXlink='http://www.w3.org/1999/xlink'
                            viewBox='0 0 1440 120'>
                            <path d='M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z'></path>
                        </svg>
                    </div>
                    <Particles
                        options={{
                            particles: {
                                number: {
                                    value: 90,
                                    density: {
                                        enable: true,
                                        value_area: 800,
                                    },
                                },
                                color: {
                                    value: '#ffffff',
                                },
                                shape: {
                                    type: 'circle',
                                    stroke: {
                                        width: 0,
                                        color: '#000000',
                                    },
                                    polygon: {
                                        nb_sides: 5,
                                    },
                                    image: {
                                        src: 'img/github.svg',
                                        width: 100,
                                        height: 100,
                                    },
                                },
                                opacity: {
                                    value: 0.8,
                                    random: true,
                                    anim: {
                                        enable: true,
                                        speed: 1,
                                        opacity_min: 0,
                                        sync: false,
                                    },
                                },
                                size: {
                                    value: 4,
                                    random: true,
                                    anim: {
                                        enable: false,
                                        speed: 4,
                                        size_min: 0.2,
                                        sync: false,
                                    },
                                },
                                line_linked: {
                                    enable: false,
                                    distance: 150,
                                    color: '#ffffff',
                                    opacity: 0.4,
                                    width: 1,
                                },
                                move: {
                                    enable: true,
                                    speed: 2,
                                    direction: 'none',
                                    random: false,
                                    straight: false,
                                    out_mode: 'out',
                                    attract: {
                                        enable: false,
                                        rotateX: 600,
                                        rotateY: 1200,
                                    },
                                },
                            },
                            interactivity: {
                                detect_on: 'canvas',
                                events: {
                                    onhover: {
                                        enable: true,
                                        mode: 'bubble',
                                    },
                                    onclick: {
                                        enable: true,
                                        mode: 'repulse',
                                    },
                                    resize: true,
                                },
                                modes: {
                                    grab: {
                                        distance: 400,
                                        line_linked: {
                                            opacity: 1,
                                        },
                                    },
                                    bubble: {
                                        distance: 400,
                                        size: 4,
                                        duration: 2,
                                        opacity: 0.8,
                                        speed: 3,
                                    },
                                    repulse: {
                                        distance: 200,
                                    },
                                    push: {
                                        particles_nb: 4,
                                    },
                                    remove: {
                                        particles_nb: 2,
                                    },
                                },
                            },
                            retina_detect: true,
                            config_demo: {
                                hide_card: false,
                                background_color: '#b61924',
                                background_image: '',
                                background_position: '50% 50%',
                                background_repeat: 'no-repeat',
                                background_size: 'cover',
                            },
                        }}
                    />
                </div>

                {/* pass the children */}
                {children}

                <footer className='footer'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='text-center'>
                                    <p className='mb-0 text-muted'>
                                        &copy; {new Date().getFullYear()}{' '}
                                        Kinderiffic. Crafted with{' '}
                                        <i className='mdi mdi-heart text-danger'></i>{' '}
                                        by Educore
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </React.Fragment>
    );
};

export default withRouter(ParticlesAuth);
