import axios from 'axios';
import { store, persistor } from '../store';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import moment from 'moment';

// FETCH DATA FOR DATATABLES
// REQUIRED PARAMS
// let parameters = {
//     offset: (page - 1) * perPage,
//     order: sort.field,
//     dir: sort.dir,
//     page: page,
//     limit: perPage,
//     search: searchKeyword,
// }
export const dtColumnConfig = (col, state) => {
    return {
        key: col,
        sortable: (col != null) ? true : false,
        omit: state.dtCols[col],
        width: '150px',
        selector: (row) => row[col],
    }
}
export const CSVHeader = (dtColumns) => {
    const filteredCol = dtColumns.filter((column) => (column.key !== null && !column.omit)).map((column) => { return { label: column.name, key: column.key } });
    return filteredCol;
};
// CSV EXPORT TABLE
export const CSVExport = (dtColumns, dtData, state, setStateWCall, done) => {
    const CSVHeaderKeys = CSVHeader(dtColumns).map((header) => header.key);
    const csvExportData = dtData.map((row) => {
        return Object.assign(
            ...Object.entries({ ...CSVHeaderKeys }).map(
                ([item, index]) => ({
                    [index]: row[index],
                })
            )
        );
    });
    setStateWCall({ ...state, dtExportCSV: csvExportData }, () => {
        done()
    })
};
// CSV EXPORT PDF
export const PDFExport = (dtColumns, dtData) => {
    const CSVHeaderKeys = CSVHeader(dtColumns).map((header) => header.key);
    const CSVHeaderColumns = CSVHeader(dtColumns).map((header) => { return { header: header.label, dataKey: header.key } });
    const csvExportData = dtData.map((row) => {
        return Object.assign(
            ...Object.entries({ ...CSVHeaderKeys }).map(
                ([item, index]) => ({
                    [index]: row[index],
                })
            )
        );
    });
    console.log('csvExportData', csvExportData);
    const doc = new jsPDF()
    autoTable(doc, ({
        columns: CSVHeaderColumns,
        body: csvExportData,
    }))
    doc.save('pdf_' + moment().unix() + '.pdf')
}

// TABLE FETCH DATA
export const tableFetch = async (controllerURL, source, parameters = {}) => {
    let state = store.getState()
    console.log('state', state);
    if (state.User.auth_key) {
        parameters = { ...parameters, auth_key: state.User.auth_key }
    }
    return await axios.get(`${process.env.REACT_APP_API_URL}/${controllerURL}`, { 'params': parameters, signal: source.signal }).catch(function (error) {
        if (axios.isCancel(error)) {
            console.log("axiosGET request cancelled", error.message, global.baseUrl + controllerURL);
        }
        if (error.response) {
            console.log('data', error.response.data);
            console.log('status', error.response.status);
            console.log('headers', error.response.headers);
        } else {
            console.log('Error', error.message);
        }
        console.log('config', error.config);
    });
};


// POST DATA
export const axiosPOST = async (controllerURL, source, fData, parameters = {}) => {
    let state = store.getState()
    console.log('state', state);
    if (state.User.auth_key) {
        parameters = { ...parameters, auth_key: state.User.auth_key }
    }
    return await axios.post(`${process.env.REACT_APP_API_URL}/${controllerURL}`, fData, { 'params': parameters, signal: source.signal }).catch(function (error) {
        if (axios.isCancel(error)) {
            console.log("axiosPOST request cancelled", error.message, global.baseUrl + controllerURL);
        }
        if (error.response) {
            console.log('data', error.response.data);
            console.log('status', error.response.status);
            console.log('headers', error.response.headers);
        } else {
            console.log('Error', error.message);
        }
        console.log('config', error.config);
    });
};

// GET DATA
export const axiosGET = async (controllerURL, source, parameters = {}) => {
    let state = store.getState()
    console.log('state', state);
    if (state.User.auth_key) {
        parameters = { ...parameters, auth_key: state.User.auth_key }
    }
    return await axios.get(`${process.env.REACT_APP_API_URL}/${controllerURL}`, { 'params': parameters, signal: source.signal }).catch(function (error) {
        if (axios.isCancel(error)) {
            console.log("axiosGET request cancelled", error.message, global.baseUrl + controllerURL);
        }
        if (error.response) {
            console.log('data', error.response.data);
            console.log('status', error.response.status);
            console.log('headers', error.response.headers);
        } else {
            console.log('Error', error.message);
        }
        console.log('config', error.config);
    });
};