import { REMOVE_CENTER, SAVE_CENTER } from './actionTypes';

export const saveCenter = (center) => {
    return {
        type: SAVE_CENTER,
        payload: center,
    };
};

export const removeCenter = () => {
    return {
        type: REMOVE_CENTER,
    };
};
