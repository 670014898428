import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers';
import rootSaga from './sagas';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import logger from 'redux-logger';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['User', 'Center'],
};

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];
// const middlewares = [sagaMiddleware, logger];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistedReducer = persistReducer(persistConfig, rootReducer);

export let store = createStore(
    persistedReducer,
    {},
    composeEnhancers(applyMiddleware(...middlewares))
);
export let persistor = persistStore(store);
sagaMiddleware.run(rootSaga);
