import React from 'react';
import { Redirect } from 'react-router-dom';

//Dashboard
import DashboardEcommerce from '../pages/DashboardEcommerce';

//login
import Login from '../pages/Authentication/Login';
import ForgetPasswordPage from '../pages/Authentication/ForgetPassword';
import Logout from '../pages/Authentication/Logout';
import Register from '../pages/Authentication/Register';
import Services from '../pages/Services';
import Employees from '../pages/Employees';
import Invoices from '../pages/Invoices';
import InvoiceDetails from '../pages/Invoices/invoice-details';

const authProtectedRoutes = [
    // { path: "/dashboard-analytics", component: DashboardAnalytics },
    // { path: "/dashboard-crm", component: DashboardCrm },
    { path: '/dashboard', component: DashboardEcommerce },
    { path: '/index', component: DashboardEcommerce },
    // this route should be at the end of all other routes
    // eslint-disable-next-line react/display-name
    { path: '/services', component: Services },
    { path: '/employees', component: Employees },
    { path: '/invoices', component: Invoices },
    { path: '/invoice-details', component: InvoiceDetails },
    {
        path: '/',
        exact: true,
        component: () => <Redirect to='/dashboard' />,
    },
];

const publicRoutes = [
    // Authentication Page
    { path: '/logout', component: Logout },
    { path: '/login', component: Login },
    { path: '/forgot-password', component: ForgetPasswordPage },
    { path: '/register', component: Register },
];

export { authProtectedRoutes, publicRoutes };
