import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row, Form, FormFeedback, Label, Input, Offcanvas, OffcanvasHeader, OffcanvasBody, Button, Card, CardBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Spinner, ButtonGroup } from 'reactstrap';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { justNums, handleFormError, showToast } from '../../helpers/common_helper';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';
import FeatherIcon from 'feather-icons-react';
import { CSVLink } from 'react-csv';
import { axiosPOST, CSVHeader, PDFExport, tableFetch, CSVExport, dtColumnConfig, axiosGET } from '../../helpers/datatable_helper';
import Swal from 'sweetalert2'
import moment from 'moment';


const Services = () => {
    document.title = 'Services | Kinderiffic';
    const offCanvasWidth = '35%';

    // States-E-Module
    const { USER, CENTER } = useSelector((state) => ({
        USER: state.User,
        CENTER: state.Center,
    }));
    const [state, setState] = useState({
        // START DT START 
        // THIS MUST BE DEFINED IF DATATABLES ARE BEING USED FOR SHOW/HIDE COLS
        dtCols: {},
        dtSort: {
            field: 'services_id',
            dir: 'DESC',
        },
        dtData: [],
        dtExportCSV: [],
        dtTotalRows: 0,
        dtPerPage: 10,
        dtCurrentPage: 1,
        dtSearchKeyword: '',
        statusAll: null,
        // START DT END 

        form_processing: false,
        services_id: 0,
        services_name: '',
        services_desc: '',
        services_fee: '',
        services_cost: '',
        services_vat: '',
        tax_exempt: 0,
        services_type: null,
        services_minutes: 60,
        services_status: 1,
        loading: false,
        validationErrors: false,
        offCanvasOpen: false,
    });

    const typeOptions = [
        { value: 'Services', label: 'Services' },
        { value: 'Inventory', label: 'Inventory' },
        { value: 'Resources', label: 'Resources' },
        { value: 'Expense', label: 'Expense' },
    ]



    //START FOR CALLBACK STATES 
    const myCallbacksList = useRef([]);
    const setStateWCall = (newState, callback) => {
        console.log('newState', newState);
        setState(newState);
        if (callback) myCallbacksList.current.push(callback)
    }
    useEffect(() => {
        console.log('useEffect', state);
        myCallbacksList.current.forEach((callback) => callback(state))
        myCallbacksList.current = [];
    }, [state]);
    //END FOR CALLBACK STATES 


    // Handle selects
    const handleType = (type) => {
        // set_services_type(type);
        setState({ ...state, services_type: type })
    };
    const selectFldStyle = {
        control: () => ({
            borderColor: handleFormError('services_type', state.errors).show ? '#f06548' : '#CFD4DA',
            borderWidth: 1,
            borderStyle: 'solid',
            display: 'flex',
            borderRadius: 5,
        }),
    };

    // Handle submit
    let handleSubmitReq;
    const handleSubmit = async (e) => {
        e.preventDefault();
        // set_form_processing(true);
        setStateWCall({ ...state, form_processing: true }, async (state) => {
            const dataForm = document.getElementById('data-form');
            const fData = new FormData(dataForm);
            fData.append('center_id', USER.center_id);
            fData.append('services_id', state.services_id);
            fData.append('Services[center_id]', USER.center_id);
            fData.set('Services[services_cost]',
                state.services_cost === '' ? 0 : state.services_cost
            );
            fData.set(
                'Services[services_vat]',
                state.services_vat === '' ? 0 : state.services_vat
            );
            fData.set('Services[tax_exempt]', state.tax_exempt);
            fData.set('Services[services_status]', state.services_status);

            if (handleSubmitReq) {
                handleSubmitReq.abort()
            }
            handleSubmitReq = new AbortController()
            let URL = 'services/'
            URL += (state.services_id) ? 'update' : 'insert'
            const res = await axiosPOST(URL, handleSubmitReq, fData)
            console.log(URL, res);
            if (res) {
                if (res.type) {
                    showToast(res.message, 'success');
                    resetForm(false);
                    fetchData(state.dtCurrentPage);
                } else {
                    showToast(res.message, 'danger');
                    setState({ ...state, errors: res.data })
                }
            }
        })
    };

    // Handle minutes
    const handleMinutes = (mins, action) => {
        if (action === null) {
            // set_services_minutes(parseInt(mins));
            setState({ ...state, services_minutes: parseInt(mins) })
        } else {
            if (action) {
                // set_services_minutes(parseInt(mins + 1));
                setState({ ...state, services_minutes: parseInt(mins + 1) })
            } else {
                // set_services_minutes(parseInt(mins - 1));
                setState({ ...state, services_minutes: parseInt(mins - 1) })
            }
        }
    };

    // Reset form
    const resetForm = (offCanvasOpen) => {
        setState({
            ...state,
            services_id: 0,
            services_name: '',
            services_desc: '',
            services_fee: '',
            services_cost: '',
            services_vat: '',
            tax_exempt: 0,
            services_status: 1,
            services_type: null,
            validationErrors: null,
            form_processing: false,
            offCanvasOpen: offCanvasOpen
        })
    };

    // Edit
    const editThis = (row) => {
        const formFields = ['services_id', 'services_name', 'services_fee', 'services_cost', 'services_type', 'services_minutes', 'services_vat', 'tax_exempt', 'services_desc', 'services_status'];
        let newState = { ...state }
        formFields.map((item, index) => {
            if ('services_type' === item) {
                newState = {
                    ...newState,
                    [item]: {
                        value: row[item],
                        label: row[item],
                    }
                }
            } else if ('tax_exempt' === item || 'services_status' === item) {
                newState = { ...newState, [item]: parseInt(row[item]) }
            } else {
                newState = { ...newState, [item]: row[item] }
            }
        });
        setState({ ...newState, offCanvasOpen: true })
    };

    // Delete
    let deleteThisReq;
    const deleteThis = (id) => {
        Swal.fire({
            title: 'DELETE!',
            text: "Are you sure!",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#f06548',
            confirmButtonText: 'Yes, Delete it!'
        }).then(async (res) => {
            if (res.value) {
                const fData = new FormData();
                fData.append('services_id', id);
                fData.append('center_id', USER.center_id);
                if (deleteThisReq) {
                    deleteThisReq.abort()
                }
                deleteThisReq = new AbortController()
                const res = await axiosPOST('services/delete', deleteThisReq, fData)
                console.log('/services/delete', res.type);
                if (res) {
                    if (res.type) {
                        showToast(res.message, 'danger');
                        fetchData(state.dtCurrentPage);
                    } else {
                        showToast(res.message, 'danger');
                        setState({ ...state, validationErrors: res.data })
                    }
                }
            }
        })

    };

    // Datatable: columns
    // BASED ON THESE STATES IT WILL FETCH THE DATA AGAIN
    useEffect(() => {
        fetchData(state.dtCurrentPage);
    }, [state.dtSort, state.dtSearchKeyword, state.statusAll]);
    const dtColumns = [
        {
            ...dtColumnConfig(null, state),
            name: 'Actions',
            selector: (row) => (
                <React.Fragment>
                    <Button
                        title='Edit'
                        onClick={() => editThis(row)}
                        color='secondary'
                        className='btn-icon btn-sm me-1'>
                        <i className='ri-pencil-fill' />
                    </Button>
                    <Button
                        title='Delete'
                        onClick={() => deleteThis(row.services_id)}
                        color='danger'
                        className='btn-icon btn-sm'>
                        <i className='ri-delete-bin-5-line' />
                    </Button>
                </React.Fragment>
            ),
            width: '100px',
        },
        {
            ...dtColumnConfig('services_status', state),
            name: 'Status',
            width: '90px',
            selector: (row) => (
                <div className='row-checkbox'>
                    <div
                        className='form-check form-switch form-switch-success form-switch-md'
                        dir='ltr'>
                        <Input
                            value={row.services_status}
                            type='checkbox'
                            className='form-check-input'
                            defaultChecked={parseInt(row.services_status)}
                            onChange={() => handleToggleStatus(row.services_id)}
                        />
                    </div>
                </div>
            ),
        },
        { ...dtColumnConfig('services_name', state), name: 'Name', },
        { ...dtColumnConfig('services_fee', state), name: 'Fee', },
        { ...dtColumnConfig('services_cost', state), name: 'Cost', },
        { ...dtColumnConfig('services_type', state), name: 'Type', },
        { ...dtColumnConfig('services_minutes', state), name: 'Minutes', },
        { ...dtColumnConfig('services_vat', state), name: 'VAT', },
        {
            ...dtColumnConfig('tax_exempt', state),
            name: 'Tax Exempt',
            selector: (row) =>
                row.tax_exempt == '0' ? (
                    <span className='badge bg-danger'>No</span>
                ) : (
                    <span className='badge bg-info'>Yes</span>
                ),
        },
    ];
    // Datatable: header component
    const subHeaderComponent = (
        <>
            <div
                className='form-check form-switch form-switch-success form-switch-md me-auto'
                style={{ marginLeft: '112px' }}>
                <Input className='form-check-input' type='checkbox' role='switch' id='switchStatusAll' defaultChecked={state.statusAll} onChange={() => setState({ ...state, statusAll: !state.statusAll })} />
                <Label className='form-check-label' for='switchStatusAll'> {state.statusAll ? 'Active' : 'All'} </Label>
            </div>
            {/* CSV,PDF DOWNLOAD BTNS */}
            <ButtonGroup className='btn btn-sm' size="sm">
                <CSVLink
                    headers={CSVHeader(dtColumns)}
                    filename={'csv_' + moment().unix() + '.csv'}
                    data={state.dtExportCSV}
                    asyncOnClick={true} onClick={(event, done) => CSVExport(dtColumns, state.dtData, state, setStateWCall, done)} className="btn btn-primary btn-sm">
                    CSV
                </CSVLink>
                <Button className='btn btn-primary btn-sm' onClick={() => PDFExport(dtColumns, state.dtData)}>PDF</Button>
            </ButtonGroup>

            <UncontrolledDropdown className='me-3'>
                <DropdownToggle
                    tag='button'
                    className='btn btn-light btn-sm'
                    id='dropdownMenuButton'>
                    Columns Visibility <i className='mdi mdi-chevron-down'></i>
                </DropdownToggle>
                <DropdownMenu>
                    {dtColumns.map((item, index) => (
                        (item.key != null) &&
                        <DropdownItem
                            key={index}
                            onClick={() => setState({ ...state, dtCols: { ...state.dtCols, [item.key]: !state.dtCols[item.key] } })}
                            className={item.omit ? 'text-muted' : ''}>
                            <FeatherIcon
                                className='me-1'
                                icon={item.omit ? 'eye-off' : 'eye'}
                                size={14}
                            />{' '}
                            {item.name}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </UncontrolledDropdown>
            <div className='form-icon right'>
                <Input
                    value={state.dtSearchKeyword}
                    type='text'
                    className='form-control form-control-sm form-control-icon'
                    id='iconleftInput'
                    placeholder='Search here...'
                    onChange={(e) => setState({ ...state, dtSearchKeyword: e.target.value })}
                />
                {(state.dtSearchKeyword != '') ? <i onClick={() => setState({ ...state, dtSearchKeyword: '' })}
                    className='mdi mdi-close-thick search-widget-icon'
                    style={{ fontSize: '15px' }}></i> : <i
                        className='mdi mdi-magnify search-widget-icon'
                        style={{ fontSize: '15px' }}></i>}
            </div>
        </>
    );


    // Datatable: fetch data
    let fetchDataReq;
    const fetchData = (page, newPerPage = state.dtPerPage) => {
        setStateWCall({ ...state, loading: true, dtCurrentPage: page, dtPerPage: newPerPage }, async (state) => {
            if (fetchDataReq) {
                fetchDataReq.abort()
            }
            fetchDataReq = new AbortController()
            let parameters = {
                center_id: USER.center_id,
                offset: (page - 1) * newPerPage,
                order: state.dtSort.field,
                dir: state.dtSort.dir,
                page: state.page,
                limit: newPerPage,
                search: state.dtSearchKeyword,
                only_active: state.statusAll
            }

            const res = await tableFetch('services/fetch', fetchDataReq, parameters)
            if (res) {
                setState({ ...state, dtData: res.data, dtTotalRows: res.total_count_filtered, loading: false })
            }
            else {
                setState({ ...state, loading: false })
            }
        })
    };



    // Datatable: toggle status
    let handleToggleStatusReq;
    const handleToggleStatus = async (id) => {
        const fData = new FormData();
        fData.append('center_id', USER.center_id);
        fData.append('services_id', id);
        if (handleToggleStatusReq) {
            handleToggleStatusReq.abort()
        }
        handleToggleStatusReq = new AbortController()
        const res = await axiosPOST('services/toggle-status', handleToggleStatusReq, fData)
        if (res) {
            if (res.type) {
                showToast(res.message, 'success');
                fetchData(state.dtCurrentPage);
            } else {
                showToast(res.message, 'danger');
                setState({ ...state, validationErrors: res.data })
            }
        }
    };

    console.log('RERENDER', state);
    return (
        <React.Fragment>
            <ToastContainer />
            <div className='page-content'>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <div
                                className='page-title-box d-sm-flex align-items-center justify-content-between'
                                style={{ minHeight: '58px' }}>
                                <h4 className='mb-sm-0'>Services</h4>
                                <Button
                                    onClick={() => resetForm(true)}
                                    color='success'>
                                    <span>Add Service</span>
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Card>
                        <CardBody>
                            <DataTable
                                columns={dtColumns}
                                data={state.dtData}
                                progressPending={state.loading}
                                pagination
                                paginationServer
                                paginationTotalRows={state.dtTotalRows}
                                onChangePage={(page) => fetchData(page)}
                                onChangeRowsPerPage={(newPerPage, page) => fetchData(page, newPerPage)}
                                paginationComponentOptions={{ selectAllRowsItem: true }}
                                subHeader
                                subHeaderComponent={subHeaderComponent}
                                onSort={(column, sortDirection) => setState({ ...state, dtSort: { field: column.key, dir: sortDirection.toUpperCase() } })}
                                sortServer
                            />
                        </CardBody>
                    </Card>
                </Container>
                <Offcanvas
                    style={{ width: offCanvasWidth }}
                    isOpen={state.offCanvasOpen}
                    toggle={() => resetForm(false)}
                    direction='end'
                    className='offcanvas-end border-0'>
                    <OffcanvasHeader
                        className='d-flex align-items-center p-3 border-bottom'
                        toggle={() => resetForm(false)}>
                        <span className='m-0 me-2'>
                            {state.services_id ? 'Update' : 'Add'} Service
                        </span>
                    </OffcanvasHeader>
                    <OffcanvasBody>
                        <Form
                            id='data-form'
                            onSubmit={handleSubmit}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                            }}>
                            <div style={{ flex: 1 }}>
                                <Row>
                                    <Col md={6}>
                                        <div className='mb-3'>
                                            <Label
                                                htmlFor='services_name'
                                                className='form-label'>
                                                Name
                                            </Label>
                                            <Input
                                                name='Services[services_name]'
                                                value={state.services_name}
                                                onChange={(e) => setState({ ...state, services_name: e.target.value })}
                                                type='text'
                                                className='form-control'
                                                placeholder=''
                                                id='services_name'
                                                invalid={handleFormError('services_name', state.validationErrors).show}
                                            />
                                            <FormFeedback>
                                                {handleFormError('services_name', state.validationErrors).msg}
                                            </FormFeedback>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className='mb-3'>
                                            <Label
                                                htmlFor='services_fee'
                                                className='form-label'>
                                                Fee
                                            </Label>
                                            <Input
                                                name='Services[services_fee]'
                                                value={state.services_fee}
                                                onChange={(e) => justNums(e.target.value) && setState({ ...state, services_fee: e.target.value })}
                                                type='text'
                                                className='form-control'
                                                placeholder=''
                                                id='services_fee'
                                                invalid={handleFormError('services_fee', state.validationErrors).show}
                                            />
                                            <FormFeedback>
                                                {handleFormError('services_fee', state.validationErrors).msg}
                                            </FormFeedback>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className='mb-3'>
                                            <Label
                                                htmlFor='services_cost'
                                                className='form-label'>
                                                Cost
                                            </Label>
                                            <Input
                                                name='Services[services_cost]'
                                                value={state.services_cost}
                                                onChange={(e) => justNums(e.target.value, true) && setState({ ...state, services_cost: e.target.value })}
                                                type='text'
                                                className='form-control'
                                                placeholder='0.00'
                                                id='services_cost'
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className='mb-3'>
                                            <Label
                                                htmlFor='services_vat'
                                                className='form-label'>
                                                VAT
                                            </Label>
                                            <Input
                                                name='Services[services_vat]'
                                                value={state.services_vat}
                                                onChange={(e) => justNums(e.target.value, true) && setState({ ...state, services_vat: e.target.value })}
                                                type='text'
                                                className='form-control'
                                                placeholder='0.00'
                                                id='services_vat'
                                            />
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className='mb-3'>
                                            <Label className='services_type'>
                                                Type
                                            </Label>
                                            <Select
                                                className={handleFormError('services_type', state.validationErrors).show && 'is-invalid'}
                                                styles={{
                                                    control: () => ({
                                                        borderColor: handleFormError('services_type', state.errors).show ? '#f06548' : '#CFD4DA',
                                                        borderWidth: 1,
                                                        borderStyle: 'solid',
                                                        display: 'flex',
                                                        borderRadius: 5,
                                                    }),
                                                }}
                                                name='Services[services_type]'
                                                value={state.services_type}
                                                onChange={() => {
                                                    handleType();
                                                }}
                                                options={typeOptions}
                                                invalid={true}
                                                id='services_type'
                                            />
                                            <FormFeedback>
                                                {handleFormError('services_type', state.validationErrors).msg}
                                            </FormFeedback>
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className='mb-3'>
                                            <Label
                                                htmlFor='services_desc'
                                                className='form-label'>
                                                Description
                                            </Label>
                                            <textarea
                                                value={state.services_desc}
                                                onChange={(e) => setState({ ...state, services_desc: e.target.value })}
                                                name='Services[services_desc]'
                                                className='form-control'
                                                id='services_desc'
                                                rows='3'></textarea>
                                        </div>
                                    </Col>
                                    <Col sm={6}>
                                        <div>
                                            <Label className='form-label'>
                                                Minutes
                                            </Label>
                                            <br />
                                            <div className='input-step'>
                                                <button
                                                    type='button'
                                                    className='minus'
                                                    onClick={() => handleMinutes(state.services_minutes, false)}>
                                                    –
                                                </button>
                                                <Input
                                                    name='Services[services_minutes]'
                                                    type='number'
                                                    className='product-quantity'
                                                    value={state.services_minutes}
                                                    style={{ width: '100px' }}
                                                    min='0'
                                                    onChange={(e) => justNums(e.target.value) && handleMinutes(e.target.value, null)}
                                                />
                                                <button
                                                    type='button'
                                                    className='plus'
                                                    onClick={() => handleMinutes(state.services_minutes, true)}>
                                                    +
                                                </button>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={3}>
                                        <div className='mb-3'>
                                            <Label
                                                htmlFor='taxEx'
                                                className='form-label'>
                                                Tax Exempt
                                            </Label>
                                            <div
                                                className='form-check form-switch form-switch-lg pt-1'
                                                dir='ltr'>
                                                <Input
                                                    name='Services[tax_exempt]'
                                                    onChange={(e) => setState({ ...state, tax_exempt: (state.tax_exempt === 0) ? 1 : 0 })}
                                                    type='checkbox'
                                                    className='form-check-input'
                                                    id='customSwitchsizelg'
                                                    defaultChecked={state.tax_exempt}
                                                    value={state.tax_exempt}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={3}>
                                        <div className='mb-3'>
                                            <Label
                                                htmlFor='status'
                                                className='form-label'>
                                                Status
                                            </Label>
                                            <div
                                                className='form-check form-switch form-switch-lg pt-1'
                                                dir='ltr'>
                                                <Input
                                                    name='Services[services_status]'
                                                    onChange={(e) => setState({ ...state, services_status: (state.services_status === 0) ? 1 : 0 })}
                                                    type='checkbox'
                                                    className='form-check-input'
                                                    id='customSwitchsizelg'
                                                    defaultChecked={
                                                        state.services_status
                                                    }
                                                    value={state.services_status}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                <Row>
                                    <Col md={12}>
                                        <div className='text-end mb-4'>
                                            <Button
                                                type='submit'
                                                disabled={state.form_processing}
                                                color={
                                                    state.services_id
                                                        ? 'warning'
                                                        : 'success'
                                                }
                                                className='btn-load'>
                                                <span className='d-flex align-items-center'>
                                                    {state.form_processing && (
                                                        <Spinner
                                                            size='sm'
                                                            className='flex-shrink-0 me-2'></Spinner>
                                                    )}
                                                    <span className='flex-grow-1'>
                                                        {state.services_id
                                                            ? 'Update'
                                                            : 'Add'}{' '}
                                                        Service
                                                    </span>
                                                </span>
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Form>
                    </OffcanvasBody>
                </Offcanvas>
            </div>
        </React.Fragment>
    );
};

export default Services;
