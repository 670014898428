import React, { useEffect, useState } from 'react';
import {
    Col,
    Container,
    Row,
    Card,
    CardHeader,
    CardBody,
    Table,
    Button,
} from 'reactstrap';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';

import logoDark from '../../assets/images/logo-dark.png';
import logoLight from '../../assets/images/logo-light.png';
import moment from 'moment';
import FeatherIcon from 'feather-icons-react';

const InvoiceDetails = (props) => {
    document.title = 'Invoice Details | Kinderiffic';

    // States-E-Module
    const { user, center } = useSelector((state) => ({
        user: state.User,
        center: state.Center,
    }));
    const [invData, setInvData] = useState(null);

    // Use effect
    useEffect(() => {
        try {
            axios
                .get(
                    `${process.env.REACT_APP_API_URL}/invoice/edit?auth_key=${user.auth_key}&inv_id=${props.location.state.inv_id}`
                )
                .then((res) => {
                    setInvData(res);
                });
        } catch (error) {
            console.log(error);
        }
    }, []);

    //Print the Invoice
    const printInvoice = () => {
        window.print();
    };

    let history = useHistory();

    if (invData === null) return null;

    const invoice = invData.invoice;
    const invoiceDetails = invData.invoicedetails.map((item, index) => (
        <tr key={index}>
            <th scope='row'>{index + 1}</th>
            <td className='text-start'>
                <span className='fw-medium'>{item.services_name}</span>
            </td>
            <td>{item.invd_line_qty}</td>
            <td>{item.invd_line_vat}</td>
            <td className='text-end'>{item.invd_line_amount}</td>
            <td className='text-end'>{item.invd_total}</td>
        </tr>
    ));

    return (
        <React.Fragment>
            <div className='page-content'>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <div
                                className='page-title-box d-sm-flex align-items-center justify-content-between'
                                style={{ minHeight: '58px' }}>
                                <h4 className='mb-sm-0'>
                                    <FeatherIcon
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => history.goBack()}
                                        className='me-1'
                                        icon='arrow-left'
                                    />
                                    Invoice Details
                                </h4>
                                <div className='hstack gap-2 justify-content-end d-print-none'>
                                    <Button
                                        onClick={printInvoice}
                                        color='success'>
                                        <i className='ri-printer-line align-bottom me-1'></i>{' '}
                                        Print
                                    </Button>
                                    <Button color='primary'>
                                        <i className='ri-mail-send-line align-bottom me-1'></i>{' '}
                                        Email PDF
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='justify-content-center'>
                        <Col xxl={9}>
                            <Card id='demo'>
                                <Row>
                                    <Col lg={12}>
                                        <CardHeader className='border-bottom-dashed p-4'>
                                            <div className='d-flex'>
                                                <div className='flex-grow-1'>
                                                    <img
                                                        src={logoDark}
                                                        className='card-logo card-logo-dark'
                                                        alt='logo dark'
                                                        height='17'
                                                    />
                                                    <img
                                                        src={logoLight}
                                                        className='card-logo card-logo-light'
                                                        alt='logo light'
                                                        height='17'
                                                    />
                                                    <div className='mt-sm-5 mt-4'>
                                                        <h6 className='text-muted text-uppercase fw-semibold'>
                                                            Address
                                                        </h6>
                                                        <p
                                                            className='text-muted mb-1'
                                                            id='address-details'>
                                                            {center.address}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className='flex-shrink-0 mt-sm-0 mt-3'>
                                                    <h6>
                                                        <span className='text-muted fw-normal'>
                                                            Email:
                                                        </span>{' '}
                                                        <span id='email'>
                                                            {center.email1}
                                                        </span>
                                                    </h6>
                                                    <h6>
                                                        <span className='text-muted fw-normal'>
                                                            Website:
                                                        </span>{' '}
                                                        <Link
                                                            to={center.urlwww}
                                                            className='link-primary'
                                                            id='website'>
                                                            {center.urlwww}
                                                        </Link>
                                                    </h6>
                                                    <h6 className='mb-0'>
                                                        <span className='text-muted fw-normal'>
                                                            Contact No:
                                                        </span>{' '}
                                                        <span id='contact-no'>
                                                            {' '}
                                                            {center.phone1}
                                                        </span>
                                                    </h6>
                                                </div>
                                            </div>
                                        </CardHeader>
                                    </Col>
                                    <Col lg={12}>
                                        <CardBody className='p-4'>
                                            <Row className='g-3'>
                                                <Col lg={3} xs={6}>
                                                    <p className='text-muted mb-2 text-uppercase fw-semibold'>
                                                        Invoice No
                                                    </p>
                                                    <h5 className='fs-14 mb-0'>
                                                        <span id='invoice-no'>
                                                            {invoice.inv_num}
                                                        </span>
                                                    </h5>
                                                </Col>
                                                <Col lg={3} xs={6}>
                                                    <p className='text-muted mb-2 text-uppercase fw-semibold'>
                                                        Date
                                                    </p>
                                                    <h5 className='fs-14 mb-0'>
                                                        <span id='invoice-date'>
                                                            {moment(
                                                                invoice.inv_data
                                                            ).format(
                                                                'DD-MM-YYYY'
                                                            )}
                                                        </span>
                                                    </h5>
                                                </Col>
                                                <Col lg={3} xs={6}>
                                                    <p className='text-muted mb-2 text-uppercase fw-semibold'>
                                                        VAT
                                                    </p>
                                                    <h5 className='fs-14 mb-0'>
                                                        <span id='total-amount'>
                                                            {invoice.inv_vat}
                                                        </span>
                                                    </h5>
                                                </Col>
                                                <Col lg={3} xs={6}>
                                                    <p className='text-muted mb-2 text-uppercase fw-semibold'>
                                                        Total Amount
                                                    </p>
                                                    <h5 className='fs-14 mb-0'>
                                                        <span id='total-amount'>
                                                            {invoice.inv_amount}
                                                        </span>
                                                    </h5>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Col>
                                    <Col lg={12}>
                                        <CardBody className='p-4 border-top border-top-dashed'>
                                            <Row className='g-3'>
                                                <Col sm={6}>
                                                    <h6 className='text-muted text-uppercase fw-semibold mb-3'>
                                                        Billing To
                                                    </h6>
                                                    <p
                                                        className='fw-medium mb-2'
                                                        id='billing-name'>
                                                        {invoice.parent_name}
                                                    </p>
                                                    {invoice.address && (
                                                        <p className='text-muted mb-1'>
                                                            {invoice.address}
                                                        </p>
                                                    )}
                                                    {invoice.parent_country && (
                                                        <p className='text-muted mb-1'>
                                                            {
                                                                invoice.parent_country
                                                            }
                                                        </p>
                                                    )}
                                                    {invoice.email1 && (
                                                        <p className='text-muted mb-1'>
                                                            {invoice.email1}
                                                        </p>
                                                    )}
                                                    {invoice.phone1 && (
                                                        <p className='text-muted mb-1'>
                                                            {invoice.phone1}
                                                        </p>
                                                    )}
                                                </Col>
                                                <Col sm={6}>
                                                    <h6 className='text-muted text-uppercase fw-semibold mb-3'>
                                                        Billing For
                                                    </h6>
                                                    <p
                                                        className='fw-medium mb-2'
                                                        id='shipping-name'>
                                                        {invoice.child_name}
                                                    </p>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Col>
                                    <Col lg={12}>
                                        <CardBody className='p-4'>
                                            <div className='table-responsive'>
                                                <Table className='table-borderless text-center table-nowrap align-middle mb-0'>
                                                    <thead>
                                                        <tr className='table-active'>
                                                            <th
                                                                scope='col'
                                                                style={{
                                                                    width: '50px',
                                                                }}>
                                                                #
                                                            </th>
                                                            <th scope='col'>
                                                                Service
                                                            </th>
                                                            <th scope='col'>
                                                                Quantity
                                                            </th>
                                                            <th scope='col'>
                                                                VAT
                                                            </th>
                                                            <th
                                                                scope='col'
                                                                className='text-end'>
                                                                Amount
                                                            </th>
                                                            <th
                                                                scope='col'
                                                                className='text-end'>
                                                                Total
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody id='products-list'>
                                                        {invoiceDetails}
                                                    </tbody>
                                                </Table>
                                            </div>
                                            <div className='border-top border-top-dashed mt-2'>
                                                <Table
                                                    className='table table-borderless table-nowrap align-middle mb-0 ms-auto'
                                                    style={{
                                                        width: '250px',
                                                    }}>
                                                    <tbody>
                                                        <tr>
                                                            <td>Sub Total</td>
                                                            <td className='text-end'>
                                                                {invoiceDetails.reduce(
                                                                    (
                                                                        prev,
                                                                        curr
                                                                    ) =>
                                                                        (
                                                                            parseInt(
                                                                                prev.invd_line_amount
                                                                            ) +
                                                                            parseInt(
                                                                                curr.invd_line_amount
                                                                            )
                                                                        ).toFixed(
                                                                            2
                                                                        )
                                                                )}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>VAT</td>
                                                            <td className='text-end'>
                                                                {
                                                                    invoice.inv_vat
                                                                }
                                                            </td>
                                                        </tr>
                                                        <tr className='border-top border-top-dashed fs-15'>
                                                            <th scope='row'>
                                                                Total Amount
                                                            </th>
                                                            <th className='text-end'>
                                                                {
                                                                    invoice.inv_amount
                                                                }
                                                            </th>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </CardBody>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default InvoiceDetails;
