import { toast } from 'react-toastify';
import axios from 'axios';
const user = JSON.parse(sessionStorage.getItem('authUser'));

// Validate Numbers
export const justNums = (val, float = false) => {
    let numRegex = /^[0-9\b]+$/;
    if (float) {
        numRegex = /^[0-9]*\.?[0-9]*$/;
    }
    if (val === '' || numRegex.test(val)) {
        return true;
    }
};

// Handle form error
export const handleFormError = (field_name, errorsObj) => {
    if (errorsObj !== null && errorsObj[field_name] !== undefined) {
        return { show: true, msg: errorsObj[field_name][0] };
    } else {
        return { show: false, msg: '' };
    }
};

// Toastr
export const showToast = (msg, nature) => {
    toast(msg, {
        position: 'top-center',
        hideProgressBar: true,
        closeOnClick: true,
        className: 'bg-' + nature + ' text-white',
    });
};

// Get roles
export const getRoles = () => {
    let result = [];
    axios
        .get(
            process.env.REACT_APP_API_URL +
                '/authentication/get-roles?auth_key=' +
                user.auth_key
        )
        .then((res) => {
            res.map((item) => {
                result.push({ value: item.role_id, label: item.role_name });
            });
        });
    return result;
};

// Get countries
export const getCountries = () => {
    let result = [];
    axios
        .get(
            process.env.REACT_APP_API_URL +
                '/authentication/get-countries?auth_key=' +
                user.auth_key
        )
        .then((res) => {
            res.map((item) => {
                result.push({ value: item.country_id, label: item.name_en });
            });
        });
    return result;
};

// In-Table thumbnail
export const Thumbnail = (props) => {
    const path =
        process.env.REACT_APP_API_URL +
        '/web/attachments/centers/' +
        user.center_id +
        '/' +
        props.moduleName +
        '/' +
        props.fileName;

    if (props.fileName !== null) {
        return (
            <img
                src={path}
                className='avatar-md rounded my-2'
                style={{ width: '35px', height: '35px' }}
            />
        );
    }
};
